
export const availableConfigs = {
    zeotap: 'zeotap',
    mapper: 'mapper',
    idp: 'idp',
    adobexp: 'adobexp'
};
export const availableFields = {
    v: 'v',
    mapperQueryParams: 'mapperQueryParams',
    disableAutoInit: 'disableAutoInit',
    gtm: 'gtm',
    adobe: 'adobe',
    useConsent: 'useConsent',
    checkForCMP: 'checkForCMP',
    tcfv: 'tcfv',
    user_country: 'user_country',
    pvtCloudEndpoint: 'pvtCloudEndpoint',
    debug: 'debug',
    onlySecureCookie: 'onlySecureCookie',
    allowCookieSync: 'allowCookieSync',
    optOut: 'optOut',
    partnerId: 'partnerId',
    allowIDP: 'allowIDP',
    enableLogging: 'enableLogging',
    logLevel: 'logLevel',
    roleForConsent: 'roleForConsent',
    shouldCheckZeotapVendorConsent: 'shouldCheckZeotapVendorConsent',
    tcfPublisherConsentCategory: 'tcfPublisherConsentCategory',
    purposesForTracking: 'purposesForTracking',
    purposesForCookieSync: 'purposesForCookieSync',
    purposesForIdentifying: 'purposesForIdentifying',
    wk: 'wk',
    orgId: 'orgId',
    storageExpirationDays: 'storageExpirationDays',
    domain: 'domain',
    allowGAClientId: 'allowGAClientId',
    gaClientIdCookiePrefix: 'gaClientIdCookiePrefix',
    gaUserIdCookieName: 'gaUserIdCookieName',
    gaUserIdOnlyLoginEvent: 'gaUserIdOnlyLoginEvent',
    partner_dom: 'partner_dom',
    symphony: 'symphony',
    areIdentitiesHashed : 'areIdentitiesHashed',
    hashIdentities: 'hashIdentities',
    environment: 'environment',
    rudderstack: 'rudderstack',
    persistInCookie: 'persistenceInCookieStorage',
    setCustomConsentBeforeGtmInit: 'setCustomConsentBeforeGtmInit',
    enableAdobeTarget: 'enableAdobeTarget',
    getParamsFromInteract: 'getParamsFromInteract',
    enableID5: 'enableID5',
    id5PartnerId: 'id5PartnerId',
    sendPartnerDataToID5: 'sendPartnerDataToID5',
    includeTCFString: 'includeTCFString',
};
export const gtmRelatedFields = {
    gtm: availableFields.gtm,
    useConsent: availableFields.useConsent,
    checkForCMP: availableFields.checkForCMP,
    tcfv: availableFields.tcfv,
    setCustomConsentBeforeGtmInit: availableFields.setCustomConsentBeforeGtmInit
}

export const adobeRelatedFields = {
    adobe: availableFields.adobe,
    useConsent: availableFields.useConsent,
    checkForCMP: availableFields.checkForCMP,
    tcfv: availableFields.tcfv
}

export const rudderStackRelatedFields = {
    rudderstack: availableFields.rudderstack
}

export const tooltips = {
    [availableFields.wk]: '',
    [availableFields.orgId]: 'Ensure that the write key belongs to this org Id',
    [availableFields.optOut]: 'if set to true no events are posted to the backend. Default : false.[this option is a fallback when consent is not used]',
    [availableFields.useConsent]: 'if set to true the sdk waits to receive a consent signal and uses that consent to manage actions.',
    [availableFields.checkForCMP]: 'if set to false Non-TCF Zeotap CMP is used for consent management.',
    [availableFields.user_country]: 'if set with the country code , this country code is sent with every request as user.user_country',
    [availableFields.pvtCloudEndpoint]: 'if set with the private cloud endpoint , this endpoint is used in place of spl endpoint',
    [availableFields.storageExpirationDays]: 'this defines how long any cookies storage using values are set for. This is not applicable on localStorage. We use cookie storage to save identities.',
    [availableFields.domain]: 'If specified cookies are stored against the mentioned domain. By default we save all cookies against the top level domain of the current hostname. Example: for www.abc.zeotap.com all cookies are saved against .zeotap.com. This allows all cookie storage to be available for subdomain as well as for the Top level domain.',
    [availableFields.onlySecureCookie]: 'If set to true all cookies are set with Secure option. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies for more info',
    [availableFields.debug]: 'if set to true , no logEvent call is made and all events are logged in console',
    [availableFields.enableLogging]: "if set to false, debug logs won't come on browser console",
    [availableFields.logLevel]: 'to enable logs for set logLevel and higher',
    [availableFields.roleForConsent]: 'to distinguish between the client role as Publisher or Vendor for Consent Resolution.',
    [availableFields.tcfPublisherConsentCategory]: 'to distinguish between Consent and LegitimateInterests if Consent Role is Publisher.',
    [availableFields.shouldCheckZeotapVendorConsent]: 'should check Zeotap vendor consent?',
    [availableFields.purposesForTracking]: 'to decide which purposes to consider for tracking and recording user activity',
    [availableFields.purposesForCookieSync]: 'to decide which purposes to consider for cookie syncing.',
    [availableFields.purposesForIdentifying]: 'to decide which purposes to consider for identifying and 3p enrichment.',
    [availableFields.allowGAClientId]: 'If set to false , Google Analytics ClientId will not be forwarded in POST calls.',
    [availableFields.gaClientIdCookiePrefix]: "cookie prefix for Google Analytics Client ID, if set with <value>, GA Client Id cookie fetch with name as '<value>_ga'.",
    [availableFields.gaUserIdCookieName]: "cookie name for Google Analytics User ID, if set with <value>, GA User Id cookie fetch with name as 'value'.",
    [availableFields.gaUserIdOnlyLoginEvent]: "if set to false, GA User ID is sent in all event calls, else only on setUserIdentities.",
    [availableFields.partnerId]: 'The id that is associated with the org, its shared by ID+ to be sent in call to idp api for fetching the idp value .',
    [availableFields.allowIDP]: 'If set to false , No IDP will be dropped on setUserIdentities call. ',
    [availableFields.areIdentitiesHashed]: 'If identities are hashed set it true else false',
    [availableFields.hashIdentities]: 'check this box if you want to hash the identities',
    [availableFields.enableID5]: 'Turn this on to enable the ID5 integration. This would enable the template to collect ID5 values along with event data.',
    [availableFields.id5PartnerId]: 'Enter the client specific ID5 Partner ID. This is provided to you by ID5.',
    [availableFields.sendPartnerDataToID5]: 'This configuration allows Collect SDK to send user PIIs (sha256 email, sha256 cellno) to ID5 if enabled.',
    logo: 'Enter the logo file url'
}

export const cookieSyncOptions = [{name: availableFields.allowCookieSync, label: 'True', value: 'true'}, {name: availableFields.allowCookieSync, label: 'False', value: 'false'}, {name: availableFields.allowCookieSync, label: 'Not set', value: 'null'}];

export const persistInCookieOptions = [{name: availableFields.persistInCookie, label: 'True', value: 'true'}, {name: availableFields.persistInCookie, label: 'False', value: 'false'}, {name: availableFields.persistInCookie, label: 'Not set', value: 'null'}];

export const optoutOptions = [{name: availableFields.optOut, label: 'True', value: 'true'}, {name: availableFields.optOut, label: 'False', value: 'false'}, {name: availableFields.optOut, label: 'Not set', value: 'null'}];

export const hashingIdentitiesOptions = [{name: availableFields.areIdentitiesHashed, label: 'True', value: 'true'}, {name: availableFields.areIdentitiesHashed, label: 'False', value: 'false'}, {name: availableFields.areIdentitiesHashed, label: 'Not set', value: 'null'}];

export const envOptions = [{name: 'qa'}, {name: 'staging'}, {name: 'prod'}, {name: 'demo-qa'}, {name: 'demo-prod'}, {name: 'development'}, {name: 'local'}];

export const tcfvOptions = [{name: availableFields.tcfv, label: 'TCF v1', value: 'tcfv1'}, {name: availableFields.tcfv, label: 'TCF v2', value: 'tcfv2'}];

export const shouldCheckZeotapVendorConsentOptions = [{name: availableFields.shouldCheckZeotapVendorConsent, label: 'True', value: 'true'}, {name: availableFields.shouldCheckZeotapVendorConsent, label: 'False', value: 'false'}, {name: availableFields.shouldCheckZeotapVendorConsent, label: 'Not set', value: 'null'}];

export const logoPositions = [{name: 'logoPosition', label: 'Left', value: 'left'}, {name: 'logoPosition', label: 'Center', value: 'center'}]

export const purposesList = [{name: 'Store and/or access information on a device', id: 1},
{name: 'Select basic ads', id: 2},
{name: 'create a personalized ad profile', id: 3},
{name: 'Select personalised ads', id: 4},
{name: 'Create a personalized content profile', id: 5},
{name: 'Select personalized content', id: 6},
{name: 'Measure ad performance', id: 7},
{name: 'Measure content performance', id: 8},
{name: 'Apply market research to generate audience insights', id: 9},
{name: 'Develop and improve products', id: 10} ]

export const tooltipCss = "relative inline-block mx-3";
export const tooltipTextCSS = "absolute invisible w-60 bg-black text-white text-center p-2 text-xs rounded-md z-1";

export const cdnUrl = 'https://content.zeotap.com/demo/'

export const moloccoSponsoredProductsURL = 'https://playground-qa.zeotap.com/moloco-poc/ad-items/' 
export const moloccoSponsoredBannerURL = 'https://playground-qa.zeotap.com/moloco-poc/ad-creative/' 
export const EMAIL_KEY = 'EMAIL_SHA256';
