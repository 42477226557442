import { setEventProperties } from "../../utils/zeotap";
import React, { useEffect } from 'react';
import {GrFormSearch} from 'react-icons/gr';
import { postData } from "../../utils/http";

export function Search({searchText, setSearchText, pageType}) {
    const [text, setText] = React.useState(searchText);

    function handleChange(e) {
        setText(e.target.value);
    }
    function handleSubmit(event) {
        event.preventDefault();
        setSearchText(text || '');
    }

    useEffect(() => {
        if (searchText && searchText.trim() !== '') {
            setEventProperties("search", { searchQuery: searchText })
        }
    }, [searchText])

    useEffect(() => {
      setText(searchText);
    }, [searchText]);
  return (
    <div className="ml-8 mb-2 bg-white search-bar w-[300px]">
      <GrFormSearch className="text-3xl" />
      <form className="mb-0" onSubmit={handleSubmit}>
        <input
          placeholder="Search Items"
          aria-label="search"
          name="search"
          className=""
          id="search"
          type="text"
          onChange={handleChange}
          value={text}
          disabled={pageType !== 'categoryPage' && pageType !== '/home/' && pageType !== '/checkout/'}
        />
      </form>
    </div>
  )
}
