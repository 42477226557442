import React, { useEffect } from 'react'
import { DENOMINATION } from '../../providers/inventoryProvider'
import { getSiteConfigField } from '../../utils/helpers'
import { setEventProperties } from '../../utils/zeotap'
import ConditionalLink from './ConditionalLink'
import Image from './Image'


const isMultiPageSite = getSiteConfigField('multiPageSite') === 'true';

const ListItem = ({ link, title, imageSrc, price, id, setSearchText,sponsored,...rest }) =>  {
  const impressionUrls = rest?.imp_trackers || [];
  const clickUrls = rest?.click_trackers || [];

  function handleClick() {
    clickUrls.forEach((url) => {
      fetch(url);
    });
    setEventProperties('viewProduct', { productID: id });
    setSearchText('');
  }

  useEffect(() => {
    // hit impression urls
    impressionUrls.forEach((url) => {
      fetch(url);
  })}, [impressionUrls]);

  return (
<div className={`inline-flex flex-col p-1 sm:p-2 w-auto max-w-max w-100 md:w-1/2 lg:w-1/4 relative`}>
<ConditionalLink redirectUrl={`/${link}`} isMultiPageSite={isMultiPageSite} onClick={() => handleClick()} >
        <div className="h-72 flex justify-center items-center bg-light hover:bg-light-200">
          <div className="flex flex-col justify-center items-center h-full">
            <Image alt={title} src={imageSrc} className="w-3/5 showcase max-h-full" />
          </div>
        </div>
      </ConditionalLink>
      <div>
        <div className="m-4 text-center font-semibold mb-1">{sponsored && <span className='px-1 bg-gray-500 rounded-lg text-xxs text-white align-middle'>Ad</span>}&nbsp;<span>{title}</span></div>
        <p className="text-center text-xs text-gray-700 mb-4">{`${DENOMINATION}${price}`}</p>
      </div>
    </div>
  );
};

export default ListItem