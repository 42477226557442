import React, { useEffect } from "react"
import { getSiteConfigField, slugify } from "../../utils/helpers"
import { setEventProperties } from "../../utils/zeotap"
import ConditionalLink from "./ConditionalLink"
import Image from "./Image"
import ListItem from "./ListItem"

const BannerImage = ({ bannerDetails, isMultiPageSite, setSearchText }) => {
  const clickUrls = bannerDetails.click_trackers || []
  const impressionUrls = bannerDetails.imp_trackers || []
  const link = slugify(bannerDetails.name) || ""
  function handleClick() {
    clickUrls.forEach(url => {
      fetch(url)
    })
    setEventProperties("viewProduct", { productID: bannerDetails.id })
    window.open(`${window.location.origin}/${link}`, "_blank")
  }

  useEffect(() => {
    // hit impression urls
    impressionUrls.forEach(url => {
      fetch(url)
    })
  }, [impressionUrls])

  return (
    <ConditionalLink
      isMultiPageSite={isMultiPageSite}
      {...bannerDetails}
    >
          <Image
            alt={bannerDetails.name}
            onClick={handleClick}
            src={bannerDetails.image_url}
            className="max-w-max max-h-full cursor-pointer"
          />
    </ConditionalLink>
  )
}

export const SponsoredBanner = ({ sponsoredBanner, setSearchText }) => {
  const isMultiPageSite = getSiteConfigField("multiPageSite") === "true"
  return (
    <>
    <div className="flex flex-row flex-wrap w-full">
    {sponsoredBanner.banner?.name && 
      <div className="flex flex-row items-center w-full justify-center bg-light pt-6 mx-2 relative">
      <span className='px-1 bg-gray-500 rounded-lg text-xxs text-white absolute top-0 left-0 ml-1 mt-1'>Ad</span>
      <BannerImage
        bannerDetails={sponsoredBanner.banner}
        isMultiPageSite={isMultiPageSite}
        setSearchText={setSearchText}
      ></BannerImage>
      </div>}
      <div className="flex flex-col w-full">
      {sponsoredBanner.items.length && <h4 className="my-2 pl-2 pt-1">Sponsored Products</h4>}
      <div className="flex flex-row flex-wrap w-full">
      {sponsoredBanner.items.map((item, index) => {
        return (
          <ListItem
            key={index}
            link={slugify(item.name)}
            id={item.id}
            title={item.name}
            price={item.price}
            imageSrc={item.image}
            sponsored={item.sponsored}
            {...item}
            setSearchText={setSearchText}
          />
        )
      })}
      </div>
      </div>
      </div>
    </>
  )
}
