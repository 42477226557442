import { prop, uniqBy } from 'ramda'
import React, { useCallback } from 'react'
import uuid from "uuid/v4"
import { EMAIL_KEY, moloccoSponsoredBannerURL, moloccoSponsoredProductsURL } from '../../utils/const'
import { addCatalogInfoToSponsoredItems, getZSKey, slugify, titleIfy } from '../../utils/helpers'
import { postData } from '../../utils/http'
import { isBrowser, setEventProperties, setPageProperties } from '../../utils/zeotap'
import CartLink from '../components/CartLink'
import ListItem from '../components/ListItem'
import LogLink from '../components/LogLink'
import { SponsoredBanner } from '../components/SponsoredBanner'
const bannerInitialState = {
  banner: {},
  items: []
}


const CategoryView = ({productMap, ...props}) => {
  const { searchText = '', setSearchText, pageContext = {} } = props;
  const { title = '', content = {} } = pageContext;
  const { items = [] } = content;
  const [sponsoredProducts, setSponsoredProducts] = React.useState([]);
  const [sponsoredBanner, setSponsoredBanner] = React.useState(bannerInitialState);
  const wk = isBrowser() && window.zeotap && typeof window.zeotap.getWriteKey === 'function' ? window.zeotap.getWriteKey() : null;
  const user_zs = getZSKey(wk);
  const user_zi = isBrowser() && window.zeotap && typeof window.zeotap.getZI === 'function'?  window.zeotap.getZI(): null;
  const user_email_sha256_lowercase = isBrowser() && window.localStorage.getItem(EMAIL_KEY);
  
  const filteredItems = React.useMemo(() => {
    const lowercaseSearch = searchText.toLowerCase();
    return items.filter(item => item.name.toLowerCase().includes(lowercaseSearch) || item.description.toLowerCase().includes(lowercaseSearch));
  }, [items, searchText]);
  
  

  const fetchSponsoredProductsList = useCallback(() => {
    const controller = new AbortController(); // Create an abort controller
    const { signal } = controller;
  
    const payload = {
      reqId: uuid(),
      event_searchQuery: searchText,
      event_eventName: "search",
      user_zi,
      user_zs,
      user_email_sha256_lowercase
    };
  
    postData(`${moloccoSponsoredProductsURL}HOME/_search`, payload, signal)
      .then(response => {
        if (!signal.aborted) { // Check if request was not aborted
          let products = response.decided_items ?? [];
          setSponsoredProducts(addCatalogInfoToSponsoredItems(products, productMap));
        }
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      });
  
    return () => controller.abort(); // Cleanup function to abort request
  }, [searchText, user_zi, user_zs, user_email_sha256_lowercase]);
  
  const fetchSponsoredBanner = useCallback(() => {
    const controller = new AbortController();
    const { signal } = controller;
  
    const payload = {
      reqId: uuid(),
      event_searchQuery: searchText,
      event_eventName: "search",
      user_zi,
      user_zs,
      user_email_sha256_lowercase
    };
  
    postData(`${moloccoSponsoredBannerURL}10000/_search`, payload, signal)
      .then(response => {
        if (!signal.aborted) {
          const bannerResponse = response ?? bannerInitialState;
          const bannerSponsoredProducts = addCatalogInfoToSponsoredItems(bannerResponse.items, productMap);
          // generate the link to navigate on banner click
          const bannerName = `${slugify(bannerSponsoredProducts[0].catalog.toLowerCase())}-${slugify(bannerSponsoredProducts?.[0].categories?.[0].toLowerCase())}`;
          const updatedBannerDetails = { banner: {name: bannerName,...bannerResponse.banner}, items: bannerSponsoredProducts };
          setSponsoredBanner(updatedBannerDetails);
        }
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      });
  
    return () => controller.abort();
  }, [searchText, user_zi, user_zs, user_email_sha256_lowercase, productMap]);
  
  // Cleanup on useEffect
  React.useEffect(() => {
    if (searchText.trim() !== '') {
      setSponsoredProducts([]);
      setSponsoredBanner(bannerInitialState);
      setEventProperties("search", { searchQuery: searchText });
      const abortFetchProducts = fetchSponsoredProductsList();
      const abortFetchBanner = fetchSponsoredBanner();
  
      return () => {
        abortFetchProducts();
        abortFetchBanner();
      };
    }
  }, [searchText, fetchSponsoredProductsList, fetchSponsoredBanner]);
  
  

  React.useEffect(() => {
    setPageProperties({
      page_category: title,
      page_category_level: '1',
      page_name: title,
      page_domain: isBrowser() && window.location.hostname
    });
      // Cleanup function to reset search text when the component is unmounted
      return () => {
        setSearchText('');
      }
  }, [title, setSearchText]);

  return (
    <>
      <CartLink />
      <LogLink />
      <div className="flex flex-col items-center">
        <div className="max-w-fw w-full flex flex-col">
          <div className="pt-10 pb-8 flex flex-row items-center justify-start">
            <h1 className="text-5xl font-light">{titleIfy(title)}</h1>
          </div>

            <div className="flex flex-col gap-6">
              {
                filteredItems.length === 0 && (sponsoredProducts.length === 0 && sponsoredBanner.items.length === 0) ? <p>No Results found</p> :
                <>
                <div className='flex flex-1 flex-wrap flex-row'>
                {uniqBy(prop('id'))([...sponsoredProducts,...filteredItems]).map((item, index) => 
                  ( 
                    <ListItem
                      key={index}
                      link={slugify(item.name)}
                      id={item.id}
                      title={item.name}
                      price={item.price}
                      imageSrc={item.image}
                      setSearchText={setSearchText}
                      sponsored={item.sponsored}
                      {...item}
                      className="w-1/2 md:w-1/4 lg:w-1/4"
                    />
                  )
                )}
                </div>
                {sponsoredBanner?.items.length !== 0 && <SponsoredBanner
                  sponsoredBanner={sponsoredBanner}
                  setSearchText={setSearchText}></SponsoredBanner>
                }
                </>
              }
            </div>
          </div>
      </div>
    </>
  )
}

export default CategoryView