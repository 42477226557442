import React, { useState, useEffect } from "react"

// to allow images to be updated on state update

async function fetchImage(src, updateSrc) {
  const image = await src
  updateSrc(image)
}

const Image = ({ src, ...props}) => {
  const [imageSrc, updateSrc] = useState(null)
  useEffect(() => {
    fetchImage(src, updateSrc)
  }, [src])
  
  return imageSrc ? <img className="h-full w-full showcase" src={imageSrc} {...props} /> : null
}

export default Image
