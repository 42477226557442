import React from 'react';
import { Link } from 'gatsby';

const ConditionalLink = ({ redirectUrl, onClick = () => {}, children, isMultiPageSite = false }) => {
  if (isMultiPageSite) {
    return (
      <a href={redirectUrl} onClick={onClick}  >
        {children}
      </a>
    );
  } else {
    return (
      <Link to={redirectUrl} onClick={onClick} >
        {children}
      </Link>
    );
  }
};

export default ConditionalLink;
